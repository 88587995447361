import { template as template_c15f0405beb54b1da4a9d0d87dd2ac5b } from "@ember/template-compiler";
const FKLabel = template_c15f0405beb54b1da4a9d0d87dd2ac5b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
