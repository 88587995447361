import { template as template_92726ca5d82f47449cd3e9394a8cdf8e } from "@ember/template-compiler";
const FKControlMenuContainer = template_92726ca5d82f47449cd3e9394a8cdf8e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
